<template>
  <v-card elevation="5">
    <v-app-bar color="indigo darken-2" dense dark>
      <v-toolbar-title>กรอกข้อมูลเพื่อค้นหา</v-toolbar-title>
    </v-app-bar>

    <v-row class="pa-2">
      <v-col sm="6" md="6" lg="3" cols="12">
        <v-text-field
          label="QUEUE NUMBER"
          v-model="query.queueId"
          prefix="Q-"
          clearable
          @keypress.enter.stop="findData"
        />
      </v-col>

      <v-col sm="6" md="6" lg="3" cols="12">
        <v-text-field
          label="รหัสอ้างอิงแรงงานต่างด้าว"
          v-model="query.refId"
          maxlength="13"
          @keypress.enter.stop="findData"
          clearable
        />
      </v-col>

      <v-col sm="6" md="6" lg="3" cols="12">
        <v-text-field
          label="เลขประจำตัวนายจ้าง/ตัวแทน"
          v-model="query.cid"
          @keypress.enter.stop="findData"
          maxlength="13"
          clearable
        />
      </v-col>

      <!-- Date Filter -->

      <v-col sm="6" md="6" lg="2" cols="12">
        <v-menu
          v-model="datepicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="วันที่จองคิว"
              v-model="query.queueDate"
              v-bind="attrs"
              v-on="on"
              clearable
              @keypress.enter.stop="findData"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="query.queueDate"
            @input="datepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col align-self="center" sm="6" md="6" lg="1" cols="12">
        <v-btn color="indigo darken-3" @click="findData" dark>
          <v-icon>mdi-magnify</v-icon>
          ค้นหา
        </v-btn>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      query: { queueId: null, refId: null, cid: null, queueDate: null },
      loading: false,
      datepicker: false
    };
  },
  methods: {
    findData() {
      if (
        this.query.queueId === null &&
        this.query.refId === null &&
        this.query.cid === null &&
        this.query.queueDate === null
      )
        return;
      this.$emit("finding", this.query);
    }
  }
};
</script>

<style>
</style>